/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
require("./bootstrap");
import Journey from "./Journey";
import slick from "slick-carousel"; // no borrar
import { isRutValid } from "./utils";
import axios from "axios";

const API_KEY = process.env.GOOGLE_MAPS_V3_APIKEY;
const script = document.createElement("script");
script.src =
    "https://maps.googleapis.com/maps/api/js?key=" +
    API_KEY +
    "&v=3.exp&libraries=geometry,drawing,places";
document.head.append(script);

//Preloader
$(window).on("load", function() {
    setTimeout(() => {
        $("#status").fadeOut();
        $("#preloader")
            .delay(600)
            .fadeOut("slow");
        $("body")
            .delay(600)
            .removeAttr("style");
    }, 150);
});

$(function() {
    // HEADER
    function setTransparentHeader() {
        if ($(window).scrollTop() > 5) {
            $(".header").removeClass("-transparent");
        } else {
            $(".header").addClass("-transparent");
        }
    }
    
    setTransparentHeader();

    if ($(".header.-transparent").length > 0) {
        $(window).on("scroll", setTransparentHeader);
    }

    $(window).on("resize", function() {
        if (
            $(this).width() >= 1170 &&
            !$(".header__toggler__icon").hasClass("-active")
        ) {
            $(".header__toggler__icon").removeClass("-active");
            $(".header__primary-nav").removeAttr("style");
        }
    });

    // Mobile menu
    $(".header__toggler__icon").on("click", function() {
        $(this).toggleClass("-active");
        $(".header__primary-nav").slideToggle(300);
    });

    // Sliders
    let $sliders = $(".js-slider");

    if ($sliders.length) {
        $sliders.each(function() {
            const slidesToShow = $(this).data("slick-slides-to-show");
            const slidesArrows =
                $(this).data("slick-arrows") == false ? false : true;
            const slidesCenter =
                $(this).data("slick-center") == true ? true : false;
    
            $(this).slick({
                mobileFirst: true,
                arrows: slidesArrows,
                centerMode: slidesCenter,
                centerPadding: "10px",
                rows: 0,
                swipeToSlide: true,
                dots: true,
                appendDots: $(this)
                    .parent()
                    .find(".js-slider-dots"),
                slidesToShow: slidesToShow[2],
                autoplay: true,
                autoplaySpeed: 4000,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow:
                                slidesToShow[0] !== undefined
                                    ? slidesToShow[0]
                                    : 1,
                        },
                    },
                    {
                        breakpoint: 750,
                        settings: {
                            slidesToShow:
                                slidesToShow[1] !== undefined
                                    ? slidesToShow[1]
                                    : 1,
                        },
                    },
                ],
            });
        });
    }
    

    // Accordion
    const $accordions = $(".js-accordion");

    if ($accordions.length) {
        $accordions.each(function() {
            const $accordion = $(this);
            let $items = $accordion.children(".js-accordion-item");

            $items.each(function() {
                let $item = $(this);
                const $button = $($item.find(".js-accordion-button")[0]);
                let $bodyWrapper = $item.children(".js-accordion-body-wrapper");
                let $body = $item.find(".js-accordion-body");

                const $scrollId = $button.attr("data-scroll");
                let $id = $($scrollId);
                let $pos;

                if (
                    typeof $scrollId !== typeof undefined &&
                    $scrollId !== false &&
                    $scrollId !== null
                ) {
                    $pos = $id.offset().top;
                }

                $button.on("click", function() {
                    $item = $($(this).parents(".js-accordion-item")[0]);
                    $items = $($item.parents(".js-accordion")[0]).children(
                        ".js-accordion-item"
                    );
                    $bodyWrapper = $item.children(".js-accordion-body-wrapper");
                    $body = $item.find(".js-accordion-body");

                    $items
                        .find(".js-accordion-body-wrapper")
                        .removeAttr("style");
                    $items.not($item).removeClass("-active");

                    $item.toggleClass("-active");

                    if ($item.hasClass("-active")) {
                        $bodyWrapper.css("height", $body.outerHeight() + "px");
                    }

                    if (
                        typeof $scrollId !== typeof undefined &&
                        $scrollId !== false &&
                        $scrollId !== null
                    ) {
                        if ($id.length === 0) {
                            return;
                        }
                        $("html,body").animate(
                            { scrollTop: $pos - 70 + "px" },
                            "slow"
                        );
                    }
                });
            });
        });
    }

    // Active navlink Header
    const navLinkItems = document.querySelectorAll(".header__primary-nav__link");
    const pathLocation = window.location.href.split("/").filter((e) => e != "");
    for (let i = 0; i < navLinkItems.length; i++) {
        if (
            navLinkItems[i].innerText.toLowerCase() ===
            pathLocation[pathLocation.length - 1]
        ) {
            navLinkItems[i].classList.add("active");
        } else {
            if (navLinkItems[i].classList.contains("active")) {
                navLinkItems[i].classList.remove("active");
            }
            navLinkItems[i].classList.remove("active");
        }
    }

    // Form input validation
    let inputs = document.querySelectorAll("input, textarea, select");
    for (let i = 0; i < inputs.length; i++) {
        inputs[i].addEventListener(
            "change",
            function(event) {
                event.target.classList.add("-interacted");
                $(".msg-success").remove();
            },
            false
        );

        if (inputs[i].value && !inputs[i].classList.contains("-interacted")) {
            inputs[i].classList.add("-interacted");
        }
    }

    $("body").on("submit", ".form", function(e) {
        e.preventDefault();

        const $this = $(this);
        const form_id = $this.attr('id');
        

        if (form_id === 'onepersonal_form') {
            let labelError = document.querySelector("#onepersonal_form .form__label-error");
            let inputError = document.getElementById("onepersonal-form-rut");

            let data = {};

            let form = JSON.parse(JSON.stringify($this.serializeArray()));

            const [
                full_name,
                email,
                rut,
                phone_number,
                city,
                station,
                model,
                period,
                kms,
                message,
            ] = form;


            if (!isRutValid(rut.value)) {
                labelError.style.display = "flex";
                inputError.style.borderColor = "#ec0000";
            } else {
                data.full_name = full_name.value;
                data.email = email.value;
                data.rut = rut.value;
                data.phone_number = phone_number.value;
                data.city = city.value;
                data.message = message.value;
                data.journey = journey.getData();
                data.station = station.value;
                data.model = model.value;
                data.period = period.value;
                data.kms = kms.value;

                axios.defaults.headers.post["X-CSRFToken"] = $(
                    'meta[name ="csrf-token"'
                ).attr("content");

                axios.post($this.attr("action"), data).then((response) => {
                    if (response.status === 201) {
                        window.journey.clearData();
                        $(
                            "<p class='msg-success'>Gracias por contactarte con nosotros!</p>"
                        ).insertBefore($this.find(".form__disclaimer").last());
                        $this.find('input[name ="name"]').val("");
                        $this.find('input[name ="email"]').val("");
                        $this.find('input[name ="rut"]').val("");
                        $this.find('input[name ="phone"]').val("");
                        $this.find('input[name ="city"]').val("");
                        $this.find('input[name ="message"]').val("");
                        $this.find('select[name ="station"]').val("");
                        $this.find('select[name ="model"]').val("");
                        $this.find('select[name ="period"]').val("");
                        $this.find('select[name ="kms"]').val("");
                        inputError.style.borderColor = "#738184";
                        labelError.style.display = "none";
                        window.openModal($("#success-modal"));
                    }
                });
            }
        }
        if (form_id === 'onebusiness_form') {
            let labelError = document.querySelector("#onebusiness_form .form__label-error");
            let inputError = document.getElementById("onebusiness-form-rut");

            let data = {};

            let form = JSON.parse(JSON.stringify($this.serializeArray()));

            const [
                company,
                rut,
                contact,
                email,
                phone,
                city,
                station,
                fleet_size,
                period,
                model,
                kms,
                message,
            ] = form;


            if (!isRutValid(rut.value)) {
                labelError.style.display = "flex";
                inputError.style.borderColor = "#ec0000";
            } else {
                data.company = company.value;
                data.rut = rut.value;
                data.contact = contact.value;
                data.email = email.value;
                data.phone_number = phone.value;
                data.city = city.value;
                data.station = station.value;
                data.fleet_size = fleet_size.value;
                data.period = period.value;
                data.model = model.value;
                data.kms = kms.value;
                data.message = message.value;
                data.journey = journey.getData();

                axios.defaults.headers.post["X-CSRFToken"] = $(
                    'meta[name ="csrf-token"'
                ).attr("content");

                axios.post($this.attr("action"), data).then((response) => {
                    if (response.status === 201) {
                        window.journey.clearData();
                        $(
                            "<p class='msg-success'>Gracias por contactarte con nosotros!</p>"
                        ).insertBefore($this.find(".form__disclaimer").last());
                        $this.find('input[name ="name"]').val("");
                        $this.find('input[name="company"]').val("");
                        $this.find('input[name="rut"]').val("");
                        $this.find('input[name="contact"]').val("");
                        $this.find('input[name="email"]').val("");
                        $this.find('input[name="phone"]').val("");
                        $this.find('input[name="city"]').val("");
                        $this.find('input[name="station"]').val("");
                        $this.find('input[name="fleet_size"]').val("");
                        $this.find('input[name="period"]').val("");
                        $this.find('input[name="model"]').val("");
                        $this.find('input[name="kms"]').val("");
                        $this.find('input[name="message"]').val("");
                        inputError.style.borderColor = "#738184";
                        labelError.style.display = "none";
                        window.openModal($("#success-modal"));

                        // dataLayer
                        dataLayer.push({
                            'event': 'trackEvent',
                            'eventCategory': 'cotizar_one_business',
                            'eventAction': 'cta',
                            'eventLabel': 'enviar'
                        });
                    }
                });

            }
        }
        if (form_id === 'share_form') {
            let labelError = document.querySelector("#share_form .form__label-error");
            let inputError = document.getElementById("contact-form-rut");

            let data = {};

            let form = JSON.parse(JSON.stringify($this.serializeArray()));
            const [
                full_name,
                email,
                rut,
                phone_number,
                city,
                service,
                message,
                section_delivered,
            ] = form;

            if (!isRutValid(rut.value)) {
                labelError.style.display = "flex";
                inputError.style.borderColor = "#ec0000";
            } else {
                let page_title_element = document.getElementById(
                    "contact-form-section_delivered"
                );
                let page_title = page_title_element.dataset?.pagetitle
                    ? page_title_element.dataset?.pagetitle
                    : "Reservar";

                data.full_name = full_name.value;
                data.email = email.value;
                data.rut = rut.value;
                data.phone_number = phone_number.value;
                data.city = city.value;
                data.service = service.value;
                data.message = message.value;
                data.journey = journey.getData();
                data.type = $this.data("form-type");
                data.section_delivered =
                    section_delivered.value === "Experiencias"
                        ? "Experiencias"
                        : section_delivered.value === page_title
                        ? page_title
                        : "Reservar";

                axios.defaults.headers.post["X-CSRFToken"] = $(
                    'meta[name ="csrf-token"'
                ).attr("content");

                axios.post($this.attr("action"), data).then((response) => {
                    if (response.status === 201) {
                        window.journey.clearData();
                        $(
                            "<p class='msg-success'>Gracias por contactarte con nosotros!</p>"
                        ).insertBefore($this.find(".form__disclaimer").last());
                        $this.find('input[name ="name"]').val("");
                        $this.find('input[name ="email"]').val("");
                        $this.find('input[name ="rut"]').val("");
                        $this.find('input[name ="phone"]').val("");
                        $this.find('input[name ="city"]').val("");
                        $this.find('select[name ="service"]').val("");
                        $this.find('input[name ="message"]').val("");
                        $this.find('input[name ="section_delivered"]').val("");
                        inputError.style.borderColor = "#738184";
                        labelError.style.display = "none";
                    }
                });
            }
        }
        if (form_id === 'onepersonalsim_form') {
            let labelError = document.querySelector("#onepersonalsim_form .form__label-error");
            let inputError = document.getElementById("onepersonalsim-form-rut");

            let data = {};

            let form = JSON.parse(JSON.stringify($this.serializeArray()));

            const [
                full_name,
                email,
                rut,
                phone_number,
                city,
                station
            ] = form;


            if (!isRutValid(rut.value)) {
                labelError.style.display = "flex";
                inputError.style.borderColor = "#ec0000";
            } else {
                data.full_name = full_name.value;
                data.email = email.value;
                data.rut = rut.value;
                data.phone_number = phone_number.value;
                data.city = city.value;
                data.station = station.value;

                axios.defaults.headers.post["X-CSRFToken"] = $(
                    'meta[name ="csrf-token"'
                ).attr("content");

                axios.post($this.attr("action"), data).then((response) => {
                    if (response.status === 201) {
                        window.journey.clearData();
                        $(
                            "<p class='msg-success'>Gracias por contactarte con nosotros!</p>"
                        ).insertBefore($this.find(".form__disclaimer").last());
                        $this.find('input[name ="name"]').val("");
                        $this.find('input[name ="email"]').val("");
                        $this.find('input[name ="rut"]').val("");
                        $this.find('input[name ="phone"]').val("");
                        $this.find('input[name ="city"]').val("");
                        $this.find('select[name ="station"]').val("");
                        inputError.style.borderColor = "#738184";
                        labelError.style.display = "none";
                        window.openModal($("#success-modal"));
                    }
                });
            }
        }
        
    });

    // Custom select
    $(".custom-select__options-option:first-of-type").hover(
        function() {
            $(this)
                .parents(".custom-select__options")
                .addClass("option-hover");
        },
        function() {
            $(this)
                .parents(".custom-select__options")
                .removeClass("option-hover");
        }
    );

    $(".custom-select__trigger").on("click", function() {
        $("html").one("click", function() {
            if ($(".js-custom-select").length) {
                $(".js-custom-select").removeClass("opened");
            }
        });
        $(this)
            .parents(".js-custom-select")
            .toggleClass("opened");
        event.stopPropagation();
    });

    $(".custom-select__options-option").on("click", function() {
        $(".custom-select__options-option.selection").removeClass("selection");
        $(this).addClass("selection");

        $(this)
            .parents(".js-custom-select")
            .find(".custom-select__text")
            .text($(this).text());
    });

    // Select de experiencias
    $(".experiences-select .custom-select__options-option").on(
        "click",
        function() {
            const $this = $(this);
            console.log($this.attr("data-value"));
            if ($this.attr("data-value")) {
                var opcion = document.getElementById($this.attr("data-value"));
                console.log(opcion, "opcion");
            }
            $(".experience-cars-container").hide();
            opcion ? (opcion.style.display = "flex") : "";
        }
    );
});

/////////////////
// MODALS
window.currentModal = false;
window.openModal = function(modal) {
    var modal = $(modal);
    if (window.currentModal) {
        window.closeCurrentModal();
        setTimeout(() => {
            modal.fadeIn(300).css("display", "flex");
            window.currentModal = modal;
        }, 300);
    } else {
        let elemento = document.querySelectorAll("#customModal");
        elemento.forEach((e) => (e.style.display = "flex"));
        modal.fadeIn(300).css("display", "flex");
        window.currentModal = modal;
    }
    $("body").addClass("modal-opened");
};
window.closeCurrentModal = function() {
    if (window.currentModal) {
        window.currentModal.fadeOut(300);
        window.currentModal = false;
        $("body").removeClass("modal-opened");
    }
};

$(function() {
    $("body").on("click", ".modal-open", function(e) {
        e.preventDefault();
        const $this = $(this);
        const $modal = $("#" + $this.data("modal"));
        if ($this.data("modal-url")) {
            $modal.load($this.data("modal-url"), function() {
                window.openModal($modal);
                let inputs = document.querySelectorAll(
                    "input, textarea, select"
                );
                for (let i = 0; i < inputs.length; i++) {
                    inputs[i].addEventListener(
                        "change",
                        function(event) {
                            event.target.classList.add("-interacted");
                            $(".msg-success").remove();
                        },
                        false
                    );

                    if (
                        inputs[i].value &&
                        !inputs[i].classList.contains("-interacted")
                    ) {
                        inputs[i].classList.add("-interacted");
                    }
                }
                $("body").on("submit", ".form", function(e) {
                    e.preventDefault();

                    const $this = $(this);

                    let labelError = document.querySelector(
                        ".form__label-error"
                    );
                    let inputError = document.getElementById(
                        "contact-form-rut"
                    );

                    let data = {};

                    let form = JSON.parse(
                        JSON.stringify($this.serializeArray())
                    );
                    const [
                        full_name,
                        email,
                        rut,
                        phone_number,
                        city,
                        message,
                        section_delivered,
                    ] = form;

                    if (!isRutValid(rut.value)) {
                        labelError.style.display = "flex";
                        inputError.style.borderColor = "#ec0000";
                    } else {
                        let page_title_element = document.getElementById(
                            "contact-form-section_delivered"
                        );
                        let page_title = page_title_element.dataset?.pagetitle
                            ? page_title_element.dataset?.pagetitle
                            : "Reservar";

                        data.full_name = full_name.value;
                        data.email = email.value;
                        data.rut = rut.value;
                        data.phone_number = phone_number.value;
                        data.city = city.value;
                        data.message = message.value;
                        data.journey = journey.getData();
                        data.type = $this.data("form-type");
                        data.section_delivered =
                            section_delivered.value === "Experiencias"
                                ? "Experiencias"
                                : section_delivered.value === page_title
                                ? page_title
                                : "Reservar";

                        axios.defaults.headers.post["X-CSRFToken"] = $(
                            'meta[name ="csrf-token"'
                        ).attr("content");
                    }
                });
            });
        } else {
            window.openModal("#" + $this.data("modal"));
            let inputs = document.querySelectorAll("input, textarea, select");
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].addEventListener(
                    "change",
                    function(event) {
                        event.target.classList.add("-interacted");
                        $(".msg-success").remove();
                    },
                    false
                );

                if (
                    inputs[i].value &&
                    !inputs[i].classList.contains("-interacted")
                ) {
                    inputs[i].classList.add("-interacted");
                }
            }
            $("body").on("submit", ".form", function(e) {
                e.preventDefault();

                const $this = $(this);
                const form_id = $this.attr('id');

                if (form_id === 'onepersonal_form' || form_id === 'onebusiness_form' || form_id === 'onepersonalsim_form') {
                    // pass
                } else {
                    let labelError = document.querySelector(".form__label-error");
                    let inputError = document.getElementById("contact-form-rut");

                    let data = {};

                    let form = JSON.parse(JSON.stringify($this.serializeArray()));
                    const [
                        full_name,
                        email,
                        rut,
                        phone_number,
                        city,
                        message,
                        section_delivered,
                    ] = form;

                    if (!isRutValid(rut.value)) {
                        labelError.style.display = "flex";
                        inputError.style.borderColor = "#ec0000";
                    } else {
                        let page_title_element = document.getElementById(
                            "contact-form-section_delivered"
                        );
                        let page_title = page_title_element.dataset?.pagetitle
                            ? page_title_element.dataset?.pagetitle
                            : "Reservar";

                        data.full_name = full_name.value;
                        data.email = email.value;
                        data.rut = rut.value;
                        data.phone_number = phone_number.value;
                        data.city = city.value;
                        data.message = message.value;
                        data.journey = journey.getData();
                        data.type = $this.data("form-type");
                        data.section_delivered =
                            section_delivered.value === "Experiencias"
                                ? "Experiencias"
                                : section_delivered.value === page_title
                                ? page_title
                                : "Reservar";

                        axios.defaults.headers.post["X-CSRFToken"] = $(
                            'meta[name ="csrf-token"'
                        ).attr("content");

                        axios.post($this.attr("action"), data).then((response) => {
                            if (response.status === 201) {
                                window.journey.clearData();
                                $(
                                    "<p class='msg-success'>Gracias por contactarte con nosotros!</p>"
                                ).insertBefore(
                                    $this.find(".form__disclaimer").last()
                                );
                                $this.find('input[name ="name"]').val("");
                                $this.find('input[name ="email"]').val("");
                                $this.find('input[name ="rut"]').val("");
                                $this.find('input[name ="phone"]').val("");
                                $this.find('input[name ="city"]').val("");
                                $this.find('input[name ="message"]').val("");
                                $this
                                    .find('input[name ="section_delivered"]')
                                    .val("");
                                inputError.style.borderColor = "#738184";
                                labelError.style.display = "none";
                            }
                        });
                    }
                }


                
            });
        }
    });

    $("body").on("click", ".modal-close", function(e) {
        e.preventDefault();
        $('.modal .msg-success').remove();
        window.closeCurrentModal();
    });

    $("body").on("mousedown", function(e) {
        if (window.currentModal && $(e.target).hasClass("modal")) {
            window.closeCurrentModal();
        }
    });
    
    //SELECTOR DE SERVICIOS EN MODELOS
    const SHARE_GRID = document.getElementById("kinto-share-grid");
    const ONE_PERSONAL_GRID = document.getElementById("kinto-one-personal-grid");
    const ONE_BUSINESS_GRID = document.getElementById("one-business-grid");
    
    const BTN_SELECTOR_SHARE = document.getElementById("selector-share");
    const BTN_SELECTOR_ONE_PERSONAL = document.getElementById("selector-one-personal");
    const BTN_SELECTOR_ONE_BUSINESS = document.getElementById("selector-one-business");

    let queryParams = new URLSearchParams(window.location.search);
    const PRESELECTED_SERVICE = queryParams.get("service");

    if(BTN_SELECTOR_SHARE){
        BTN_SELECTOR_SHARE.addEventListener('click', () => {
            SHARE_GRID.classList.add('show');
            ONE_PERSONAL_GRID.classList.remove('show');
            ONE_BUSINESS_GRID.classList.remove('show');
            BTN_SELECTOR_SHARE.classList.add('selected');
            BTN_SELECTOR_ONE_PERSONAL.classList.remove('selected');
            BTN_SELECTOR_ONE_BUSINESS.classList.remove('selected');
        });
        if(PRESELECTED_SERVICE === 'share'){
            BTN_SELECTOR_SHARE.click();
        }
    }
    if(BTN_SELECTOR_ONE_PERSONAL){
        BTN_SELECTOR_ONE_PERSONAL.addEventListener('click', () => {
            ONE_PERSONAL_GRID.classList.add('show');
            SHARE_GRID.classList.remove('show');
            ONE_BUSINESS_GRID.classList.remove('show');
            BTN_SELECTOR_ONE_PERSONAL.classList.add('selected');
            BTN_SELECTOR_SHARE.classList.remove('selected');
            BTN_SELECTOR_ONE_BUSINESS.classList.remove('selected');
        })
        if(PRESELECTED_SERVICE === 'onepersonal'){
            BTN_SELECTOR_ONE_PERSONAL.click();
        }
    }
    if(BTN_SELECTOR_ONE_BUSINESS){
        BTN_SELECTOR_ONE_BUSINESS.addEventListener('click', () => {
            ONE_BUSINESS_GRID.classList.add('show');
            ONE_PERSONAL_GRID.classList.remove('show');
            SHARE_GRID.classList.remove('show');
            BTN_SELECTOR_ONE_BUSINESS.classList.add('selected');
            BTN_SELECTOR_ONE_PERSONAL.classList.remove('selected');
            BTN_SELECTOR_SHARE.classList.remove('selected');
        })
        if(PRESELECTED_SERVICE === 'onebusiness'){
            BTN_SELECTOR_ONE_BUSINESS.click();
        }
    }

    //FAQ selector
    const SHARE_QUESTIONS_SECTION = document.getElementById("share-questions-container");
    const ONE_QUESTIONS_SECTION = document.getElementById("one-questions-container");
    
    const BTN_SELECTOR_SHARE_QUESTIONS = document.getElementById("selector-share-questions");
    const BTN_SELECTOR_ONE_QUESTIONS = document.getElementById("selector-one-questions");
    

    if(BTN_SELECTOR_SHARE_QUESTIONS){
        BTN_SELECTOR_SHARE_QUESTIONS.addEventListener('click', () => {
            SHARE_QUESTIONS_SECTION.classList.add('show');
            ONE_QUESTIONS_SECTION.classList.remove('show');
            BTN_SELECTOR_SHARE_QUESTIONS.classList.add('selected');
            BTN_SELECTOR_ONE_QUESTIONS.classList.remove('selected');
        });
    }
    if(BTN_SELECTOR_ONE_QUESTIONS){
        BTN_SELECTOR_ONE_QUESTIONS.addEventListener('click', () => {
            ONE_QUESTIONS_SECTION.classList.add('show');
            SHARE_QUESTIONS_SECTION.classList.remove('show');

            BTN_SELECTOR_ONE_QUESTIONS.classList.add('selected');
            BTN_SELECTOR_SHARE_QUESTIONS.classList.remove('selected');
        })
    }
});
