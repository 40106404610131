
/* eslint-disable no-unused-vars */
export const isRutValid = (rut) => {
    if (!rut) return false;

    let sum = 0;
    let rut_clean = String(rut)
        .toLowerCase()
        .match(/[\dk]+/g);
    if (!rut_clean) return false;
    rut_clean = rut_clean.join("");
    const rut_body = rut_clean.slice(0, -1);
    const rut_vd = rut_clean.slice(-1);
    const multipliers = [2, 3, 4, 5, 6, 7];

    if (rut_body.length < 7) return false;

    for (let i = 0; i < rut_body.length; i++) {
        const num = rut_body[rut_body.length - 1 - i];
        const by =
            i < multipliers.length
                ? multipliers[i]
                : multipliers[i % multipliers.length];
        const subtotal = num * by;
        sum += num * by;
    }

    const vd = 11 - (sum % 11);
    if (vd === 10 && rut_vd === "k") return true;
    if (vd === 11 && rut_vd === "0") return true;
    if (String(vd) === rut_vd) return true;

    // console.log({rut, rut_clean, rut_body, rut_vd, sum, vd});
    return false;
};
